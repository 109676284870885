import React from 'react'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import PresseMagazine from '../../components/references/PresseMagazine'

const PresseMagazinePage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentReferencesPresseMagazine">
    <PresseMagazine />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('presse-magazine')
)

export default echance(PresseMagazinePage)
